import React from "react";

function HamburgerIcon(props: any) {
    return (
        <svg width="26" height="13" viewBox="0 0 26 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
            <line x1="1.66667" y1="10.9993" x2="24.3333" y2="10.9993" stroke="black" stroke-opacity="0.54" stroke-width="3.33333" stroke-linecap="round" />
            <line x1="1.66667" y1="2.33333" x2="24.3333" y2="2.33333" stroke="black" stroke-opacity="0.54" stroke-width="3.33333" stroke-linecap="round" />
        </svg>

    )
}

export default HamburgerIcon;