import { Box, Drawer, DrawerBody, DrawerContent, DrawerOverlay, IconButton, Link, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, useDisclosure } from "@chakra-ui/react";
import Homepage from "../pages/homepage";
import Contact from "../pages/contact";
import Logo from "./logo";
import AboutUs from "../pages/about_us";
import Project from "../pages/project";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import HamburgerIcon from "./hamburgericon";
import '../App.css';
import LanguageButton from "./language_button";
import { useTranslation } from "react-i18next";

type ContentType = {
  initialTabIndex: number
}
const Content = ({ initialTabIndex }: ContentType) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const { isOpen, onOpen, onClose } = useDisclosure();

  const colors: string[] = ["background", 'background', 'background', 'highlight']
  const [tabIndex, setTabIndex] = useState(0)
  const bg = colors[tabIndex]

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleTabChange = (index: number) => {
    setTabIndex(index);
    switch (index) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/project');
        break;
      case 2:
        navigate('/team');
        break;
      case 3:
        navigate('/contact');
        break;
      // Add more cases for additional tabs
      default:
        navigate('/');
    }
  };

  // Based on the current URL, determine the initial tab index
  useEffect(() => {
    switch (location.pathname) {
      case '/':
        handleTabChange(0);
        break;
      case '/project':
        handleTabChange(1);
        break;
      case '/team':
        handleTabChange(2);
        break;
      case '/contact':
        handleTabChange(3);
        break;
      // Add more cases for additional tabs
      default:
        handleTabChange(initialTabIndex);
    }
  }, [location.pathname, initialTabIndex]);

  return (
    <Box bg={bg} bgSize="cover" position="relative">
      {isMobile ? (
        <>
          <IconButton
            icon={<HamburgerIcon color={location.pathname === "/contact" ? "background" : "black"} />}
            aria-label="Menu"
            onClick={onOpen}
            variant="ghost"
            zIndex={100}
            borderRadius="50%"
            position="absolute"
            top="3rem"
            right="1rem"
          />
          <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerBody>
                <VStack spacing={4}>
                  <Link href="#/" onClick={onClose}>{t('navbar.home')}</Link>
                  <Link href="#/project" onClick={onClose}>{t('navbar.project')}</Link>
                  <Link href="#/team" onClick={onClose}>{t('navbar.about_us')}</Link>
                  <Link href="#/contact" onClick={onClose}>{t('navbar.contact')}</Link>
                  <LanguageButton/>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          {/* Render TabPanels for mobile */}
          <Tabs index={initialTabIndex}>
            <TabPanels textAlign="start">
              <TabPanel sx={{ margin: 0, padding: 0 }}><Homepage /></TabPanel>
              <TabPanel sx={{ margin: 0, padding: 0 }}>
                <Logo color='black' />
                <Project />
              </TabPanel>
              <TabPanel sx={{ margin: 0, padding: 0 }} >
                <Logo color='black' />
                <AboutUs />
              </TabPanel>
              <TabPanel sx={{ margin: 0, padding: 0 }}><Logo color='background' /><Contact /></TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <><LanguageButton/>
        <Tabs index={initialTabIndex} isLazy align='end' position="relative" variant='unstyled' size={['sm', 'lg']} onChange={handleTabChange}>
          <TabList sx={{ marginBottom: ["-7.5rem", "-8.5rem"] }} padding={"2rem"} paddingRight={["0rem", "3rem"]} className="NavBarAnimation">
            <Tab color={tabIndex === 3 ? 'background' : 'black'} _selected={{ opacity: 1, fontSize: ['sm', '3xl'] }} sx={{ fontWeight: 600, opacity: 0.5 }} fontSize={['xs', '2xl']}>{t('navbar.home')}</Tab>
            <Tab color={tabIndex === 3 ? 'background' : 'black'} _selected={{ opacity: 1, fontSize: ['sm', '3xl'] }} sx={{ fontWeight: 600, opacity: 0.5 }} fontSize={['xs', '2xl']}>{t('navbar.project')}</Tab>
            <Tab color={tabIndex === 3 ? 'background' : 'black'} _selected={{ opacity: 1, fontSize: ['sm', '3xl'] }} sx={{ fontWeight: 600, opacity: 0.5 }} fontSize={['xs', '2xl']}>{t('navbar.about_us')}</Tab>
            <Tab color={tabIndex === 3 ? 'background' : 'black'} _selected={{ opacity: 1, fontSize: ['sm', '3xl'] }} sx={{ fontWeight: 600, opacity: 0.5 }} fontSize={['xs', '2xl']}>{t('navbar.contact')}</Tab>
          </TabList>
          <TabPanels textAlign="start">
            <TabPanel sx={{ margin: 0, padding: 0 }}><Homepage /></TabPanel>
            <TabPanel sx={{ margin: 0, padding: 0 }}>
              <Logo color='black' />
              <Project /></TabPanel>
            <TabPanel sx={{ margin: 0, padding: 0 }} >
              <Logo color='black' />
              <AboutUs /></TabPanel>
            <TabPanel sx={{ margin: 0, padding: 0 }}><Logo color='background' /><Contact /></TabPanel>
          </TabPanels>
        </Tabs>
        </>
      )}
    </Box>
  );
};

export default Content;